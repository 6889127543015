import styled from 'styled-components';

export const ContentTitle = styled.h2`
  margin-bottom: 20px;
`;

export const HalfField = styled.div`
  width: 49%;
  margin-bottom: 10px;
`;

export const OneFourthField = styled.div`
  width: 24%;
  margin-bottom: 10px;
`;

export const TwoFourthField = styled.div`
  width: 49%;
  margin-bottom: 10px;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
`;

export const CenterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
