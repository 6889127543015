import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 250px;
  background-color: #22222f;
  padding-top: 10px;

  > div {
    margin: 0 20px;
    padding: 10px 0;
    border-bottom: 1px solid #fff;

    h3 {
      padding: 10px;
    }

    > button {
        background: transparent;
        border-radius: 10px;
        color: #fff;
        border: 0;
        transition: background-color 0.2s;
        width: 100%;
        display: flex;

        &:hover {
          background-color: ${shade(0.5, '#22222f')};
        }
      }
    }

    ul {
      li {
        list-style: none;
        margin-left: 10px;

        button {
          background: transparent;
          border-radius: 10px;
          padding: 10px;
          font-size: 14px;
          color: #fff;
          border: 0;
          transition: background-color 0.2s;
          width: 100%;
          text-align: start;

          &:hover {
            background-color: ${shade(0.5, '#22222f')};
          }
        }

        h5 {
          color: ${shade(0.5, '#fff')};
        }
      }
    }
  }
`;

export const CharactersContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    background-color: transparent;
    border: none;

    svg {
      margin-left: 15px;
      margin-top: 7px;
      color: #fff;
    }
  }
`;
