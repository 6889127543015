import React from 'react';

import { Container } from './styles';

const Maintenance: React.FC = () => {
  return (
    <Container>
      <h1>
        Sentimos muito por isso, mas o sistema está em manutenção, tente
        novamente mais tarde...
      </h1>
    </Container>
  );
};

export default Maintenance;
