import styled from 'styled-components';

export const Container = styled.div`
  width: 33%;
  background-color: #22222f;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;

  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
