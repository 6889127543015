import styled from 'styled-components';

export const ContentTitle = styled.h2`
  margin-bottom: 20px;
`;

export const HalfField = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const OneSixtyField = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CenterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
