import styled from 'styled-components';

export const ContentTitle = styled.h2`
  margin-bottom: 20px;
`;

export const OneThirdContainer = styled.div`
  width: 32%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

export const OneThirdField = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const CenterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
