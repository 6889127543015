import styled from 'styled-components';

export const ContentTitle = styled.h2`
  margin-bottom: 20px;
`;

export const OneFourthField = styled.div`
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const InputField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ThreeFourthField = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const DeleteButtonContainer = styled.div`
  width: 14%;
`;

export const OneSixtyField = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 10px;
`;

export const OneFourthContainer = styled.div`
  width: 24%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CenterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
