import styled from 'styled-components';

export const ContentTitle = styled.h2`
  margin-bottom: 20px;
`;

export const FieldsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const OneFourthField = styled.div`
  width: 24%;
  margin-bottom: 10px;
`;

export const HalfContainer = styled.div`
  width: 49%;
  margin-bottom: 10px;
`;

export const CenterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
