import styled from 'styled-components';

export const ContentTitle = styled.h2`
  margin-bottom: 20px;
`;

export const OneThirdContainer = styled.div`
  width: 32%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InputField = styled.div`
  width: 89%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const TextAreaField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const DeleteButtonContainer = styled.div`
  width: 10%;
`;

export const CenterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const OneSixtyField = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 10px;
`;
