/* eslint-disable camelcase */
import styled, { css } from 'styled-components';

import characterImg from '../../assets/character.png';

interface IFilledLifeContainerProps {
  life_points: number;
  maximum_life_points: number;
}

interface IFilledSanityContainerProps {
  sanity_points: number;
  maximum_sanity_points: number;
}

export const Container = styled.div`
  height: 154px;
  padding-top: 56px;
`;

export const ImageContainer = styled.div`
  background: url(${characterImg}) no-repeat center;
  background-size: cover;
  width: 130px;
  height: 154px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

export const NameContainer = styled.div`
  width: 213px;
  height: 34px;
  background-color: #fff;
  margin-left: 120px;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    color: #000;
  }
`;

export const LifeContainer = styled.div`
  width: 197px;
  height: 26px;
  background-color: #77382f;
  margin-left: 122px;
  border: 1px solid #000;
`;

export const FilledLife = styled.div<IFilledLifeContainerProps>`
  height: 100%;
  background-color: #701409;

  ${(props) =>
    css`
      width: ${Math.floor(
        props.life_points * (100 / props.maximum_life_points)
      )}%;
    `}
`;

export const SanityContainer = styled.div`
  width: 219px;
  height: 26px;
  background-color: #3860a2;
  margin-left: 100px;
  border: 1px solid #000;
`;

export const FilledSanity = styled.div<IFilledSanityContainerProps>`
  height: 100%;
  background-color: #0d429f;

  ${(props) =>
    css`
      width: ${Math.floor(
        props.sanity_points * (100 / props.maximum_sanity_points)
      )}%;
    `}
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 154px;
  padding-top: 90px;
`;

export const LifeText = styled.div`
  width: 197px;
  height: 26px;
  margin-left: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SanityText = styled.div`
  width: 219px;
  height: 26px;
  margin-left: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
