import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #c53030;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
  color: #22222f;

  &:hover {
    background-color: ${shade(0.3, '#c53030')};
  }
`;
