import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;

export const Content = styled.div`
  width: 100%;
  padding: 20px;
`;

export const OneFourthContainer = styled.div`
  width: 24%;
`;

export const PasswordContainer = styled.div`
  background: ${shade(0.4, '#5c5c87')};
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  height: 56px;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const List = styled.div`
  margin-top: 10px;
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const HalfLine = styled.div`
  width: 50%;
  display: flex;
  height: 56px;
  border: 1px solid #5c5c87;
  box-sizing: border-box;
`;

export const EntireLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  border: 1px solid #5c5c87;
  box-sizing: border-box;
`;

export const TwoFifthContainer = styled.div`
  width: 39%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const OneFifthContainer = styled.div`
  width: 19%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
