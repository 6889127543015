import styled, { css } from 'styled-components';
import { shade } from 'polished';

import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  isDisabled: boolean;
  isAlignedCenter?: boolean;
}

export const TextAreaContainer = styled.div<ContainerProps>`
  textarea {
    width: 100%;
    height: 150px;
    padding: 16px;
    resize: none;
    color: #fff;
    border-radius: 10px;
    border: 2px solid #232129;
    background-color: #5c5c87;

    ${(props) =>
      props.isDisabled &&
      css`
        background-color: ${shade(0.2, '#5c5c87')};
      `}

    ${(props) =>
      props.isErrored &&
      css`
        border-color: #c53030;
      `}

    ${(props) =>
      props.isFocused &&
      css`
        border-color: #ff9000;
      `}

    /* ${(props) =>
      props.isFilled &&
      css`
        border-color: #ff9000;
      `} */
  }
`;

export const Container = styled.div``;

export const InputTitleContainer = styled.div`
  height: 17px;
`;

export const InputSmallerTitle = styled.h5`
  font-size: 11px;
  text-align: center;
`;

export const InputTitle = styled.h5`
  margin-left: 10px;
`;

export const InputContainer = styled.div<ContainerProps>`
  background: #5c5c87;
  border-radius: 10px;
  padding: 16px;
  width: 100%;

  border: 2px solid #232129;
  color: #fff;

  display: flex;
  align-items: center;

  /* & + div {
    margin-top: 8px;
  } */

  ${(props) =>
    props.isDisabled &&
    css`
      background: ${shade(0.4, '#5c5c87')};
    `}

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border-color: #ff9000;
    `}

  /* ${(props) =>
    props.isFilled &&
    css`
      border-color: #ff9000;
    `} */

  svg {
    margin-right: 16px;
  }

  input {
    width: 100%;
    background: transparent;
    border: 0;
    color: #fff;

    &::placeholder {
      color: #fff;
    }

    ${(props) =>
      props.isAlignedCenter &&
      css`
        text-align: center;
      `}
  }
`;

export const InputSubtitleContainer = styled.div`
  margin: 0;
  height: 17px;
`;

export const InputSubtitle = styled.h5`
  font-size: 11px;
  text-align: center;
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
