import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Records from '../pages/Records';
import Users from '../pages/Users';
import Characters from '../pages/Characters';
import CharactersDisplay from '../pages/CharactersDisplay';
// import NotFound from '../pages/NotFound';
import Maintenance from '../pages/Maintenance';

const { REACT_APP_MAINTENANCE_STATUS: maintenanceStatus } = process.env;

const Routes: React.FC = () => (
  <Switch>
    {maintenanceStatus === 'active' ? (
      <Route component={Maintenance} />
    ) : (
      <>
        <Route path="/" exact component={SignIn} />

        <Route path="/ficha" component={Records} isPrivate />
        <Route path="/jogadores" component={Users} isPrivate />

        <Route path="/personagem" exact component={Characters} isPrivate />
        <Route path="/personagem/:character_id" component={CharactersDisplay} />

        {/* <Route path="*" component={NotFound} /> */}
      </>
    )}
  </Switch>
);

export default Routes;
